@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background-color: #f0f0f0;
}

.color-primary {
    color: #0d3b69;
}

.classic-reading-background {
    background-image: url('/images/readingbg.jpg');
    background-repeat: repeat;
}

.blur-background {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.92);
}

.blur-background-3px {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.video-background {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.hebrew-font {
    font-family: 'TaameyFrank', serif;
}

.ancient-hebrew-font {
    font-family: 'TaameyFrank', serif;
}

.font-lato-bold {
    font-family: LatoBold;
}

.hebrew-font.text-lg,
.ancient-hebrew-font.text-lg {
    font-size: 1.7rem;
    line-height: 1.9rem;
}

.hebrew-font.text-md,
.ancient-hebrew-font.text-md {
    font-size: 1.6rem;
    line-height: 1;
}

.underline-links a {
    text-decoration: underline;
}